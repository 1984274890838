<template>
	<div class="app-container">
		<div class="filter-container">
			<div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">组合套餐名称: </label>
					<el-input v-model="searchData.activityName" clearable style='width:300px' placeholder="请输入组合套餐名称"></el-input>
				</div>
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="search">搜索</el-button>
					<button-permissions :datas="'addCombine'">
						<el-button type="primary" style="width:100px;margin-right:20px;" @click="handleDetailShow({id:0},0)">添加套餐</el-button>
					</button-permissions>
					<button-permissions :datas="'delCombine'">
						<el-button type="warning" style="width:100px;margin-right:20px;" @click="sureDel({},1)">批量删除</el-button>
					</button-permissions>
				</div>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" :row-key="getRowKeys" @selection-change="handleSelectionChange" border>
                <el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="comboName" label="组合套餐名称"></el-table-column>
				<el-table-column label="组合套餐价格" >
					<template slot-scope="scope">
						<div>￥{{scope.row.comboPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="组合套餐折扣" >
					<template slot-scope="scope">
						<div>{{scope.row.avgDiscount}}折</div>
					</template>
				</el-table-column>
				<el-table-column prop="stateValue" label="状态"></el-table-column>
				<el-table-column prop="startTime" label="开始时间" ></el-table-column>
				<el-table-column prop="endTime" label="结束时间"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<button-permissions :datas="'combineEdit'">
							<div class="table-button" @click="handleDetailShow(scope.row,1)">编辑</div>
						</button-permissions>
						<button-permissions :datas="'combineDetail'">
							<div class="table-button" @click="handleDetailShow(scope.row,2)">查看</div>
						</button-permissions>
						<button-permissions :datas="'combineDel'">
							<div class="table-button" @click="sureDel(scope.row,2)">删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				v-if="searchData.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="searchData.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchData.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import {
		comboList,
		comboDelete,
	} from '@/api/wyUsedInterface.js'
import buttonPermissions from '@/components/buttonPermissions';
export default {
    name:'refundAfterOrder',
	components: {
		buttonPermissions
	},
	data () {
		return {
			applyTime: '',  //时间
			searchData: {
				activityName: '',//活动名称
				applyStartTime: '',    //申请开始时间
                applyEndTime: '',      //申请结束时间
				total: 0,
				pageNo: 1,
				size: 20
			},
            loading: false,
			refundStatus: [{
				value: 1,
				label: '未开始'
			}, {
				value: 2,
				label: '秒杀中'
			}, {
				value: 3,
				label: '已结束'
			}],
			tableData: [],
			multipleSelection:[]
		}
	},
	async beforeMount () {
		this.getList();
	},
	computed: {

	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			try {
				let data = {
					pageNo:this.searchData.pageNo,
					pageSize : this.searchData.size,
					comboName:this.searchData.activityName,
				};
				let result = '';
				result = await comboList(data);
				result.data.records.map(item=>{
					item.stateValue = item.shelfStatus==-1 ? '未上架': '已上架';
					return item;
				})
				this.tableData = result.data.records;
				this.searchData.total = result.data.total;
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		//搜索
		search () {
			this.searchData.pageNo = 1
			this.searchData.isDownload = false;    //下载文件标记
			if (!this.searchData.total) {
				this.searchData.size = 20
			}
			this.getList()
		},
		//跳转至详情
		handleDetailShow (row,type) {
			//type=1是编辑 2是查看
            let params = {
                id: row.comboId,
				type:type
            }
			this.publicToDetailsR(params,'/market/combinedGoods/editCombinedGoods',this);
		},
		async handleFinish(row){
			let data = {
				comboIds:[row.comboId]
			};
			let res = await comboDelete(data);
			if(res.code==200){
				if(res.success){
					this.$message({
						showClose: true,
						type: 'success',
						message: "删除成功"
					});
					this.getList();
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			}else{
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.searchData.size = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.searchData.pageNo = val;
			this.getList();
		},
		handleSelectionChange(val) {
			console.log('----------',val);
			this.multipleSelection = val;
		},
		getRowKeys (row) {
			return row.id;
		},
		sureDel(obj,type){
			if(type==1){
				if(this.multipleSelection.length==0){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择要删除的组合活动'
					});
					return;
				}
			}
			this.$alert('确定删除所选组合活动吗', '温馨提示', {
				confirmButtonText: '确定',
				showCancelButton: true,
				callback: action => {
					if (action == 'confirm') {
						if(type==1){
							this.handleDelBatch();
						}else{
							this.handleFinish(obj);
						}
					}
				}
			});
		},
		//删除
		async handleDelBatch(){
			var ids = [];
			this.multipleSelection.map(item=>{
				ids.push(item.comboId);
				return item;
			})
			let data = {
				comboIds:ids
			};
			let res = await comboDelete(data);
			if(res.code==200){
				if(res.success){
					this.$message({
						showClose: true,
						type: 'success',
						message: "删除成功"
					});
					this.getList();
				}else{
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			}else{
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
		}
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
